import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
};

export type AddFavorite = {
  __typename?: 'AddFavorite';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type Address = Node & {
  __typename?: 'Address';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type AddUserFeedback = {
  __typename?: 'AddUserFeedback';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type AddUserPaymentMethod = {
  __typename?: 'AddUserPaymentMethod';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type Business = Node & {
  __typename?: 'Business';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  taxId: Scalars['String'];
  handle: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  mcc?: Maybe<Scalars['String']>;
  defaultCurrency?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  tosAcceptance?: Maybe<Scalars['Boolean']>;
  chargesEnabled?: Maybe<Scalars['Boolean']>;
  taxIdProvided?: Maybe<Scalars['Boolean']>;
  payoutsEnabled?: Maybe<Scalars['Boolean']>;
  managerId?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  manager?: Maybe<User>;
  address?: Maybe<Address>;
  purposes?: Maybe<PurposeConnection>;
  favoritedBy?: Maybe<UserConnection>;
  source?: Maybe<PaymentMethod>;
  transactions?: Maybe<TransactionConnection>;
};


export type BusinessPurposesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type BusinessFavoritedByArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type BusinessTransactionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BusinessConnection = {
  __typename?: 'BusinessConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BusinessEdge>>;
};

/** A Relay edge containing a `Business` and its cursor. */
export type BusinessEdge = {
  __typename?: 'BusinessEdge';
  /** The item at the end of the edge */
  node?: Maybe<Business>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type BusinessInput = {
  taxId: Scalars['String'];
  handle: Scalars['String'];
  active: Scalars['Boolean'];
  name: Scalars['String'];
  /** Stripe merchant category codes. */
  mcc?: Maybe<BusinessMcc>;
  stripeId?: Maybe<Scalars['String']>;
  /** Business Website */
  website?: Maybe<Scalars['String']>;
  /** GivFast ID */
  manager?: Maybe<Scalars['String']>;
  /** Tokenized Bank Account */
  source?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
};

export enum BusinessMcc {
  /** Charitable and Social Service Organizations - Fundraising */
  Chssof = 'CHSSOF',
  /** Religious Organizations */
  Regorg = 'REGORG',
  /** Religious Goods Stores */
  Reggst = 'REGGST'
}

/** An enumeration. */
export enum BusinessSortEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TaxIdAsc = 'TAX_ID_ASC',
  TaxIdDesc = 'TAX_ID_DESC',
  HandleAsc = 'HANDLE_ASC',
  HandleDesc = 'HANDLE_DESC',
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  FriendlyNameAsc = 'FRIENDLY_NAME_ASC',
  FriendlyNameDesc = 'FRIENDLY_NAME_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  MccAsc = 'MCC_ASC',
  MccDesc = 'MCC_DESC',
  DefaultCurrencyAsc = 'DEFAULT_CURRENCY_ASC',
  DefaultCurrencyDesc = 'DEFAULT_CURRENCY_DESC',
  WebsiteAsc = 'WEBSITE_ASC',
  WebsiteDesc = 'WEBSITE_DESC',
  StripeIdAsc = 'STRIPE_ID_ASC',
  StripeIdDesc = 'STRIPE_ID_DESC',
  TosAcceptanceAsc = 'TOS_ACCEPTANCE_ASC',
  TosAcceptanceDesc = 'TOS_ACCEPTANCE_DESC',
  ChargesEnabledAsc = 'CHARGES_ENABLED_ASC',
  ChargesEnabledDesc = 'CHARGES_ENABLED_DESC',
  TaxIdProvidedAsc = 'TAX_ID_PROVIDED_ASC',
  TaxIdProvidedDesc = 'TAX_ID_PROVIDED_DESC',
  PayoutsEnabledAsc = 'PAYOUTS_ENABLED_ASC',
  PayoutsEnabledDesc = 'PAYOUTS_ENABLED_DESC',
  ManagerIdAsc = 'MANAGER_ID_ASC',
  ManagerIdDesc = 'MANAGER_ID_DESC',
  AddressIdAsc = 'ADDRESS_ID_ASC',
  AddressIdDesc = 'ADDRESS_ID_DESC'
}

export type ContactUs = {
  __typename?: 'ContactUs';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};


export type DeleteUser = {
  __typename?: 'DeleteUser';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type GenericResponse = {
  __typename?: 'GenericResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Login = {
  __typename?: 'Login';
  user?: Maybe<User>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Token>;
};

export type MakeTransaction = {
  __typename?: 'MakeTransaction';
  status?: Maybe<Scalars['Boolean']>;
  transaction?: Maybe<Transaction>;
};

export type Mutation = {
  __typename?: 'Mutation';
  contactUs?: Maybe<ContactUs>;
  makeTransaction?: Maybe<MakeTransaction>;
  addUserPaymentMethod?: Maybe<AddUserPaymentMethod>;
  removeUserPaymentMethod?: Maybe<RemoveUserPaymentMethod>;
  updateUserPaymentMethod?: Maybe<UpdateUserPaymentMethod>;
  verifyUserPaymentMethod?: Maybe<VerifyUserPaymentMethod>;
  registerBusiness?: Maybe<RegisterBusiness>;
  registerUser?: Maybe<RegisterUser>;
  updateUser?: Maybe<UpdateUser>;
  addFavorite?: Maybe<AddFavorite>;
  removeFavorite?: Maybe<RemoveFavorite>;
  deleteUser?: Maybe<DeleteUser>;
  resetPassword?: Maybe<ResetPassword>;
  addUserFeedback?: Maybe<AddUserFeedback>;
  updateUserPreferences?: Maybe<UpdateUserPreferences>;
};


export type MutationContactUsArgs = {
  clientId: Scalars['String'];
  email: Scalars['String'];
  message: Scalars['String'];
  reason: Scalars['String'];
};


export type MutationMakeTransactionArgs = {
  amount: Scalars['Float'];
  destination: Scalars['ID'];
  key: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  paymentMethodId: Scalars['ID'];
  purpose: Scalars['ID'];
};


export type MutationAddUserPaymentMethodArgs = {
  paymentMethod?: Maybe<RegisterPaymentMethodInput>;
};


export type MutationRemoveUserPaymentMethodArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateUserPaymentMethodArgs = {
  id: Scalars['ID'];
  paymentMethod?: Maybe<UpdatePaymentMethodInput>;
};


export type MutationVerifyUserPaymentMethodArgs = {
  first: Scalars['Float'];
  id: Scalars['ID'];
  second: Scalars['Float'];
};


export type MutationRegisterBusinessArgs = {
  business?: Maybe<BusinessInput>;
};


export type MutationRegisterUserArgs = {
  clientId: Scalars['String'];
  password: Scalars['String'];
  profileData?: Maybe<UserInput>;
  stripeId?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  id?: Maybe<Scalars['ID']>;
  userInfo?: Maybe<UserInput>;
};


export type MutationAddFavoriteArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveFavoriteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationResetPasswordArgs = {
  clientId?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};


export type MutationAddUserFeedbackArgs = {
  notes: Scalars['String'];
};


export type MutationUpdateUserPreferencesArgs = {
  language: Scalars['String'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PaymentMethod = Node & {
  __typename?: 'PaymentMethod';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  nickName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['String']>;
  expYear?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  businessId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  business?: Maybe<Business>;
  paymentMethod?: Maybe<TransactionConnection>;
};


export type PaymentMethodPaymentMethodArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PaymentMethodConnection = {
  __typename?: 'PaymentMethodConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentMethodEdge>>;
};

/** A Relay edge containing a `PaymentMethod` and its cursor. */
export type PaymentMethodEdge = {
  __typename?: 'PaymentMethodEdge';
  /** The item at the end of the edge */
  node?: Maybe<PaymentMethod>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Purpose = Node & {
  __typename?: 'Purpose';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  businessId?: Maybe<Scalars['String']>;
};

export type PurposeConnection = {
  __typename?: 'PurposeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PurposeEdge>>;
};

/** A Relay edge containing a `Purpose` and its cursor. */
export type PurposeEdge = {
  __typename?: 'PurposeEdge';
  /** The item at the end of the edge */
  node?: Maybe<Purpose>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** The ID of the object */
  transaction?: Maybe<Transaction>;
  listTransaction?: Maybe<TransactionConnection>;
  business?: Maybe<Business>;
  listBusiness?: Maybe<BusinessConnection>;
  login?: Maybe<Login>;
  refreshToken?: Maybe<Token>;
  /** The ID of the object */
  user?: Maybe<User>;
  me?: Maybe<User>;
  listUser?: Maybe<UserConnection>;
  forgotPassword?: Maybe<GenericResponse>;
  /** The ID of the object */
  node?: Maybe<Node>;
};


export type QueryTransactionArgs = {
  id: Scalars['ID'];
};


export type QueryListTransactionArgs = {
  sort?: Maybe<Array<Maybe<TransactionSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryBusinessArgs = {
  id: Scalars['ID'];
};


export type QueryListBusinessArgs = {
  sort?: Maybe<Array<Maybe<BusinessSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
  clientId: Scalars['String'];
};


export type QueryRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryListUserArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryForgotPasswordArgs = {
  email: Scalars['String'];
  clientId: Scalars['String'];
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type RegisterBusiness = {
  __typename?: 'RegisterBusiness';
  success?: Maybe<Scalars['Boolean']>;
  business?: Maybe<Business>;
};

export type RegisterPaymentMethodInput = {
  token?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type RegisterUser = {
  __typename?: 'RegisterUser';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  token?: Maybe<Token>;
};

export type RemoveFavorite = {
  __typename?: 'RemoveFavorite';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type RemoveUserPaymentMethod = {
  __typename?: 'RemoveUserPaymentMethod';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type ResetPassword = {
  __typename?: 'ResetPassword';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type Token = {
  __typename?: 'Token';
  refresh: Scalars['String'];
  access: Scalars['String'];
};

export type Transaction = Node & {
  __typename?: 'Transaction';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  date?: Maybe<Scalars['DateTime']>;
  lastModified?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  appFee?: Maybe<Scalars['Float']>;
  sourceId?: Maybe<Scalars['String']>;
  destinationId?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  purposeId?: Maybe<Scalars['String']>;
  notifiedCreated: Scalars['Boolean'];
  notifiedResolved: Scalars['Boolean'];
  notifiedBusiness: Scalars['Boolean'];
  source?: Maybe<User>;
  destination?: Maybe<Business>;
  paymentMethod?: Maybe<PaymentMethod>;
  purpose?: Maybe<Purpose>;
};

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TransactionEdge>>;
};

/** A Relay edge containing a `Transaction` and its cursor. */
export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  /** The item at the end of the edge */
  node?: Maybe<Transaction>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum TransactionSortEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  LastModifiedAsc = 'LAST_MODIFIED_ASC',
  LastModifiedDesc = 'LAST_MODIFIED_DESC',
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  StripeIdAsc = 'STRIPE_ID_ASC',
  StripeIdDesc = 'STRIPE_ID_DESC',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  AppFeeAsc = 'APP_FEE_ASC',
  AppFeeDesc = 'APP_FEE_DESC',
  SourceIdAsc = 'SOURCE_ID_ASC',
  SourceIdDesc = 'SOURCE_ID_DESC',
  DestinationIdAsc = 'DESTINATION_ID_ASC',
  DestinationIdDesc = 'DESTINATION_ID_DESC',
  PaymentMethodIdAsc = 'PAYMENT_METHOD_ID_ASC',
  PaymentMethodIdDesc = 'PAYMENT_METHOD_ID_DESC',
  PurposeIdAsc = 'PURPOSE_ID_ASC',
  PurposeIdDesc = 'PURPOSE_ID_DESC',
  NotifiedCreatedAsc = 'NOTIFIED_CREATED_ASC',
  NotifiedCreatedDesc = 'NOTIFIED_CREATED_DESC',
  NotifiedResolvedAsc = 'NOTIFIED_RESOLVED_ASC',
  NotifiedResolvedDesc = 'NOTIFIED_RESOLVED_DESC',
  NotifiedBusinessAsc = 'NOTIFIED_BUSINESS_ASC',
  NotifiedBusinessDesc = 'NOTIFIED_BUSINESS_DESC'
}

export type UpdatePaymentMethodInput = {
  nickName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['String']>;
  expYear?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type UpdateUser = {
  __typename?: 'UpdateUser';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type UpdateUserPaymentMethod = {
  __typename?: 'UpdateUserPaymentMethod';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type UpdateUserPreferences = {
  __typename?: 'UpdateUserPreferences';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type User = Node & {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  username?: Maybe<Scalars['String']>;
  authHash?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePersonId?: Maybe<Scalars['String']>;
  accounts?: Maybe<BusinessConnection>;
  transactions?: Maybe<TransactionConnection>;
  paymentMethods?: Maybe<PaymentMethodConnection>;
  favorites?: Maybe<BusinessConnection>;
  profile?: Maybe<UserProfile>;
};


export type UserAccountsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserTransactionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserPaymentMethodsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserFavoritesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserEdge>>;
};

/** A Relay edge containing a `User` and its cursor. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** The item at the end of the edge */
  node?: Maybe<User>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type UserInput = {
  username?: Maybe<Scalars['String']>;
  authHash?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  last4SSN?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  /** User Accepted Terms of User and Privacy Policy */
  acceptedTerms?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
};

export type UserProfile = Node & {
  __typename?: 'UserProfile';
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  acceptedTerms?: Maybe<Scalars['Boolean']>;
  dob?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  last4SSN?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  primaryPaymentMethodId?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  primaryPaymentMethod?: Maybe<PaymentMethod>;
  address?: Maybe<Address>;
  user?: Maybe<User>;
};

export type VerifyUserPaymentMethod = {
  __typename?: 'VerifyUserPaymentMethod';
  success?: Maybe<Scalars['Boolean']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type BusinessFieldsFragment = (
  { __typename?: 'Business' }
  & Pick<Business, 'id' | 'name' | 'friendlyName' | 'handle' | 'description' | 'website'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'street1' | 'street2' | 'city' | 'state' | 'zipcode' | 'phone'>
  )>, purposes?: Maybe<(
    { __typename?: 'PurposeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'PurposeEdge' }
      & { node?: Maybe<(
        { __typename?: 'Purpose' }
        & Pick<Purpose, 'id' | 'name' | 'description'>
      )> }
    )>> }
  )>, manager?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { profile?: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'email' | 'id' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type PmFieldsFragment = (
  { __typename?: 'PaymentMethod' }
  & Pick<PaymentMethod, 'id' | 'type' | 'last4' | 'fullName' | 'nickName' | 'brand' | 'bankName' | 'expMonth' | 'expYear' | 'status' | 'createdAt'>
);

export type UserFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username'>
  & { transactions?: Maybe<(
    { __typename?: 'TransactionConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'TransactionEdge' }
      & Pick<TransactionEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Transaction' }
        & Pick<Transaction, 'id' | 'amount' | 'status' | 'date' | 'lastModified' | 'notes' | 'createdAt'>
        & { purpose?: Maybe<(
          { __typename?: 'Purpose' }
          & Pick<Purpose, 'id' | 'name'>
        )>, destination?: Maybe<(
          { __typename?: 'Business' }
          & Pick<Business, 'id' | 'name' | 'handle'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'id' | 'street1' | 'street2' | 'city' | 'state' | 'zipcode'>
          )>, manager?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id'>
            & { profile?: Maybe<(
              { __typename?: 'UserProfile' }
              & Pick<UserProfile, 'id' | 'firstName' | 'lastName'>
            )> }
          )> }
        )>, paymentMethod?: Maybe<(
          { __typename?: 'PaymentMethod' }
          & Pick<PaymentMethod, 'id' | 'type' | 'last4' | 'fullName' | 'nickName' | 'brand' | 'bankName' | 'expMonth' | 'expYear' | 'status' | 'createdAt'>
        )> }
      )> }
    )>> }
  )>, profile?: Maybe<(
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id' | 'firstName' | 'lastName' | 'acceptedTerms' | 'dob' | 'language'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'street1' | 'street2' | 'city' | 'state' | 'zipcode'>
    )>, primaryPaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethod' }
      & Pick<PaymentMethod, 'id' | 'type' | 'last4' | 'fullName' | 'nickName' | 'brand' | 'bankName' | 'expMonth' | 'expYear' | 'status' | 'createdAt'>
    )> }
  )>, paymentMethods?: Maybe<(
    { __typename?: 'PaymentMethodConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'PaymentMethodEdge' }
      & Pick<PaymentMethodEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'PaymentMethod' }
        & Pick<PaymentMethod, 'id' | 'type' | 'last4' | 'fullName' | 'nickName' | 'brand' | 'bankName' | 'expMonth' | 'expYear' | 'status' | 'createdAt'>
      )> }
    )>> }
  )>, favorites?: Maybe<(
    { __typename?: 'BusinessConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Array<Maybe<(
      { __typename?: 'BusinessEdge' }
      & Pick<BusinessEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Business' }
        & Pick<Business, 'id' | 'name' | 'handle'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'street1' | 'street2' | 'city' | 'state' | 'zipcode'>
        )>, manager?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'username'>
          & { profile?: Maybe<(
            { __typename?: 'UserProfile' }
            & Pick<UserProfile, 'id' | 'firstName' | 'lastName'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type AddFavoriteMutationVariables = Exact<{
  id: Scalars['ID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type AddFavoriteMutation = (
  { __typename?: 'Mutation' }
  & { addFavorite?: Maybe<(
    { __typename?: 'AddFavorite' }
    & Pick<AddFavorite, 'success'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserFieldsFragment
    )> }
  )> }
);

export type AddUserFeedbackMutationVariables = Exact<{
  notes: Scalars['String'];
}>;


export type AddUserFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { addUserFeedback?: Maybe<(
    { __typename?: 'AddUserFeedback' }
    & Pick<AddUserFeedback, 'success' | 'message'>
  )> }
);

export type AddUserPaymentMethodMutationVariables = Exact<{
  data: RegisterPaymentMethodInput;
}>;


export type AddUserPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { addUserPaymentMethod?: Maybe<(
    { __typename?: 'AddUserPaymentMethod' }
    & Pick<AddUserPaymentMethod, 'success'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username'>
      & { paymentMethods?: Maybe<(
        { __typename?: 'PaymentMethodConnection' }
        & { pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
        ), edges: Array<Maybe<(
          { __typename?: 'PaymentMethodEdge' }
          & Pick<PaymentMethodEdge, 'cursor'>
          & { node?: Maybe<(
            { __typename?: 'PaymentMethod' }
            & PmFieldsFragment
          )> }
        )>> }
      )> }
    )>, paymentMethod?: Maybe<(
      { __typename?: 'PaymentMethod' }
      & PmFieldsFragment
    )> }
  )> }
);

export type ContactUsMutationVariables = Exact<{
  clientId: Scalars['String'];
  email: Scalars['String'];
  reason: Scalars['String'];
  message: Scalars['String'];
}>;


export type ContactUsMutation = (
  { __typename?: 'Mutation' }
  & { contactUs?: Maybe<(
    { __typename?: 'ContactUs' }
    & Pick<ContactUs, 'message' | 'success'>
  )> }
);

export type MakeTransactionMutationVariables = Exact<{
  paymentMethodId: Scalars['ID'];
  destination: Scalars['ID'];
  amount: Scalars['Float'];
  purpose: Scalars['ID'];
  key: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
}>;


export type MakeTransactionMutation = (
  { __typename?: 'Mutation' }
  & { makeTransaction?: Maybe<(
    { __typename?: 'MakeTransaction' }
    & Pick<MakeTransaction, 'status'>
    & { transaction?: Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id' | 'stripeId' | 'amount' | 'status' | 'date' | 'lastModified' | 'notes'>
      & { source?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'username'>
      )>, destination?: Maybe<(
        { __typename?: 'Business' }
        & Pick<Business, 'handle'>
      )> }
    )> }
  )> }
);

export type RegisterUserMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
  clientId: Scalars['String'];
  data: UserInput;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { registerUser?: Maybe<(
    { __typename?: 'RegisterUser' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserFieldsFragment
    )>, token?: Maybe<(
      { __typename?: 'Token' }
      & Pick<Token, 'access' | 'refresh'>
    )> }
  )> }
);

export type RemoveFavoriteMutationVariables = Exact<{
  id: Scalars['ID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type RemoveFavoriteMutation = (
  { __typename?: 'Mutation' }
  & { removeFavorite?: Maybe<(
    { __typename?: 'RemoveFavorite' }
    & Pick<RemoveFavorite, 'success'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserFieldsFragment
    )> }
  )> }
);

export type RemoveUserPaymentMethodMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveUserPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { removeUserPaymentMethod?: Maybe<(
    { __typename?: 'RemoveUserPaymentMethod' }
    & Pick<RemoveUserPaymentMethod, 'success'>
    & { paymentMethod?: Maybe<(
      { __typename?: 'PaymentMethod' }
      & PmFieldsFragment
    )> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  token?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  clientId?: Maybe<Scalars['String']>;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPassword' }
    & Pick<ResetPassword, 'message' | 'success'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  data?: Maybe<UserInput>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUser' }
    & Pick<UpdateUser, 'success'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserFieldsFragment
    )> }
  )> }
);

export type UpdateUserPaymentMethodMutationVariables = Exact<{
  id: Scalars['ID'];
  data: UpdatePaymentMethodInput;
}>;


export type UpdateUserPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { updateUserPaymentMethod?: Maybe<(
    { __typename?: 'UpdateUserPaymentMethod' }
    & Pick<UpdateUserPaymentMethod, 'success'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username'>
      & { profile?: Maybe<(
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'id'>
        & { primaryPaymentMethod?: Maybe<(
          { __typename?: 'PaymentMethod' }
          & PmFieldsFragment
        )> }
      )>, paymentMethods?: Maybe<(
        { __typename?: 'PaymentMethodConnection' }
        & { edges: Array<Maybe<(
          { __typename?: 'PaymentMethodEdge' }
          & { node?: Maybe<(
            { __typename?: 'PaymentMethod' }
            & PmFieldsFragment
          )> }
        )>> }
      )> }
    )>, paymentMethod?: Maybe<(
      { __typename?: 'PaymentMethod' }
      & PmFieldsFragment
    )> }
  )> }
);

export type UpdateUserPreferencesMutationVariables = Exact<{
  language: Scalars['String'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type UpdateUserPreferencesMutation = (
  { __typename?: 'Mutation' }
  & { updateUserPreferences?: Maybe<(
    { __typename?: 'UpdateUserPreferences' }
    & Pick<UpdateUserPreferences, 'success' | 'message'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserFieldsFragment
    )> }
  )> }
);

export type VerifyUserPaymentMethodMutationVariables = Exact<{
  id: Scalars['ID'];
  first: Scalars['Float'];
  second: Scalars['Float'];
}>;


export type VerifyUserPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { verifyUserPaymentMethod?: Maybe<(
    { __typename?: 'VerifyUserPaymentMethod' }
    & Pick<VerifyUserPaymentMethod, 'success'>
    & { paymentMethod?: Maybe<(
      { __typename?: 'PaymentMethod' }
      & PmFieldsFragment
    )> }
  )> }
);

export type MyBusinessQueryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MyBusinessQueryQuery = (
  { __typename?: 'Query' }
  & { business?: Maybe<(
    { __typename?: 'Business' }
    & BusinessFieldsFragment
  )> }
);

export type ForgotPasswordQueryVariables = Exact<{
  email: Scalars['String'];
  clientId: Scalars['String'];
}>;


export type ForgotPasswordQuery = (
  { __typename?: 'Query' }
  & { forgotPassword?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message' | 'success'>
  )> }
);

export type GetMyTransactionsQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type GetMyTransactionsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { profile?: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id' | 'firstName' | 'lastName'>
    )>, transactions?: Maybe<(
      { __typename?: 'TransactionConnection' }
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ), edges: Array<Maybe<(
        { __typename?: 'TransactionEdge' }
        & Pick<TransactionEdge, 'cursor'>
        & { node?: Maybe<(
          { __typename?: 'Transaction' }
          & Pick<Transaction, 'id' | 'amount' | 'status' | 'date' | 'lastModified' | 'notes' | 'createdAt'>
          & { purpose?: Maybe<(
            { __typename?: 'Purpose' }
            & Pick<Purpose, 'id' | 'name'>
          )>, destination?: Maybe<(
            { __typename?: 'Business' }
            & Pick<Business, 'id' | 'name' | 'handle'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'id' | 'street1' | 'street2' | 'city' | 'state' | 'zipcode'>
            )>, manager?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'id'>
              & { profile?: Maybe<(
                { __typename?: 'UserProfile' }
                & Pick<UserProfile, 'id' | 'firstName' | 'lastName'>
              )> }
            )> }
          )>, paymentMethod?: Maybe<(
            { __typename?: 'PaymentMethod' }
            & Pick<PaymentMethod, 'id' | 'type' | 'last4' | 'fullName' | 'nickName' | 'brand' | 'bankName' | 'expMonth' | 'expYear' | 'status' | 'createdAt'>
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type GetPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { profile?: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id'>
      & { primaryPaymentMethod?: Maybe<(
        { __typename?: 'PaymentMethod' }
        & PmFieldsFragment
      )> }
    )>, paymentMethods?: Maybe<(
      { __typename?: 'PaymentMethodConnection' }
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ), edges: Array<Maybe<(
        { __typename?: 'PaymentMethodEdge' }
        & Pick<PaymentMethodEdge, 'cursor'>
        & { node?: Maybe<(
          { __typename?: 'PaymentMethod' }
          & PmFieldsFragment
        )> }
      )>> }
    )> }
  )> }
);

export type ListTransactionQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type ListTransactionQuery = (
  { __typename?: 'Query' }
  & { listTransaction?: Maybe<(
    { __typename?: 'TransactionConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'TransactionEdge' }
      & { node?: Maybe<(
        { __typename?: 'Transaction' }
        & Pick<Transaction, 'amount' | 'date' | 'status'>
        & { destination?: Maybe<(
          { __typename?: 'Business' }
          & Pick<Business, 'handle'>
        )> }
      )> }
    )>> }
  )> }
);

export type LoginUserQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
  clientId: Scalars['String'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type LoginUserQuery = (
  { __typename?: 'Query' }
  & { login?: Maybe<(
    { __typename?: 'Login' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserFieldsFragment
    )>, token?: Maybe<(
      { __typename?: 'Token' }
      & Pick<Token, 'access'>
    )> }
  )> }
);

export type MeQueryQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type MeQueryQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & UserFieldsFragment
  )> }
);

export type MyFavoritesQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type MyFavoritesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & { favorites?: Maybe<(
      { __typename?: 'BusinessConnection' }
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ), edges: Array<Maybe<(
        { __typename?: 'BusinessEdge' }
        & Pick<BusinessEdge, 'cursor'>
        & { node?: Maybe<(
          { __typename?: 'Business' }
          & Pick<Business, 'id' | 'name' | 'handle'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'id' | 'street1' | 'street2' | 'city' | 'state' | 'zipcode'>
          )>, manager?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'username'>
            & { profile?: Maybe<(
              { __typename?: 'UserProfile' }
              & Pick<UserProfile, 'id' | 'firstName' | 'lastName'>
            )> }
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export const BusinessFieldsFragmentDoc = gql`
    fragment businessFields on Business {
  id
  name
  friendlyName
  handle
  address {
    id
    street1
    street2
    city
    state
    zipcode
    phone
  }
  description
  purposes {
    edges {
      node {
        id
        name
        description
      }
    }
  }
  manager {
    id
    profile {
      email
      id
      firstName
      lastName
    }
  }
  website
}
    `;
export const PmFieldsFragmentDoc = gql`
    fragment pmFields on PaymentMethod {
  id
  type
  last4
  fullName
  nickName
  brand
  bankName
  expMonth
  expYear
  status
  createdAt
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment userFields on User {
  id
  username
  transactions(before: $before, after: $after, first: $first, last: $last) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        purpose {
          id
          name
        }
        destination {
          id
          name
          handle
          address {
            id
            street1
            street2
            city
            state
            zipcode
          }
          manager {
            id
            profile {
              id
              firstName
              lastName
            }
          }
        }
        paymentMethod {
          id
          type
          last4
          fullName
          nickName
          brand
          bankName
          expMonth
          expYear
          status
          createdAt
        }
        amount
        status
        date
        lastModified
        notes
        createdAt
      }
    }
  }
  profile {
    id
    firstName
    lastName
    acceptedTerms
    address {
      id
      street1
      street2
      city
      state
      zipcode
    }
    dob
    primaryPaymentMethod {
      id
      type
      last4
      fullName
      nickName
      brand
      bankName
      expMonth
      expYear
      status
      createdAt
    }
    language
  }
  paymentMethods(before: $before, after: $after, first: $first, last: $last) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        type
        last4
        fullName
        nickName
        brand
        bankName
        expMonth
        expYear
        status
        createdAt
      }
    }
  }
  favorites(before: $before, after: $after, first: $first, last: $last) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        name
        handle
        address {
          id
          street1
          street2
          city
          state
          zipcode
        }
        manager {
          id
          username
          profile {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
}
    `;
export const AddFavoriteDocument = gql`
    mutation AddFavorite($id: ID!, $before: String, $after: String, $first: Int, $last: Int) {
  addFavorite(id: $id) {
    success
    user {
      ...userFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type AddFavoriteMutationFn = Apollo.MutationFunction<AddFavoriteMutation, AddFavoriteMutationVariables>;

/**
 * __useAddFavoriteMutation__
 *
 * To run a mutation, you first call `useAddFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavoriteMutation, { data, loading, error }] = useAddFavoriteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAddFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<AddFavoriteMutation, AddFavoriteMutationVariables>) {
        return Apollo.useMutation<AddFavoriteMutation, AddFavoriteMutationVariables>(AddFavoriteDocument, baseOptions);
      }
export type AddFavoriteMutationHookResult = ReturnType<typeof useAddFavoriteMutation>;
export type AddFavoriteMutationResult = Apollo.MutationResult<AddFavoriteMutation>;
export type AddFavoriteMutationOptions = Apollo.BaseMutationOptions<AddFavoriteMutation, AddFavoriteMutationVariables>;
export const AddUserFeedbackDocument = gql`
    mutation AddUserFeedback($notes: String!) {
  addUserFeedback(notes: $notes) {
    success
    message
  }
}
    `;
export type AddUserFeedbackMutationFn = Apollo.MutationFunction<AddUserFeedbackMutation, AddUserFeedbackMutationVariables>;

/**
 * __useAddUserFeedbackMutation__
 *
 * To run a mutation, you first call `useAddUserFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserFeedbackMutation, { data, loading, error }] = useAddUserFeedbackMutation({
 *   variables: {
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useAddUserFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<AddUserFeedbackMutation, AddUserFeedbackMutationVariables>) {
        return Apollo.useMutation<AddUserFeedbackMutation, AddUserFeedbackMutationVariables>(AddUserFeedbackDocument, baseOptions);
      }
export type AddUserFeedbackMutationHookResult = ReturnType<typeof useAddUserFeedbackMutation>;
export type AddUserFeedbackMutationResult = Apollo.MutationResult<AddUserFeedbackMutation>;
export type AddUserFeedbackMutationOptions = Apollo.BaseMutationOptions<AddUserFeedbackMutation, AddUserFeedbackMutationVariables>;
export const AddUserPaymentMethodDocument = gql`
    mutation AddUserPaymentMethod($data: RegisterPaymentMethodInput!) {
  addUserPaymentMethod(paymentMethod: $data) {
    user {
      id
      username
      paymentMethods {
        pageInfo {
          endCursor
          startCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            ...pmFields
          }
        }
      }
    }
    success
    paymentMethod {
      ...pmFields
    }
  }
}
    ${PmFieldsFragmentDoc}`;
export type AddUserPaymentMethodMutationFn = Apollo.MutationFunction<AddUserPaymentMethodMutation, AddUserPaymentMethodMutationVariables>;

/**
 * __useAddUserPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddUserPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserPaymentMethodMutation, { data, loading, error }] = useAddUserPaymentMethodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddUserPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<AddUserPaymentMethodMutation, AddUserPaymentMethodMutationVariables>) {
        return Apollo.useMutation<AddUserPaymentMethodMutation, AddUserPaymentMethodMutationVariables>(AddUserPaymentMethodDocument, baseOptions);
      }
export type AddUserPaymentMethodMutationHookResult = ReturnType<typeof useAddUserPaymentMethodMutation>;
export type AddUserPaymentMethodMutationResult = Apollo.MutationResult<AddUserPaymentMethodMutation>;
export type AddUserPaymentMethodMutationOptions = Apollo.BaseMutationOptions<AddUserPaymentMethodMutation, AddUserPaymentMethodMutationVariables>;
export const ContactUsDocument = gql`
    mutation ContactUs($clientId: String!, $email: String!, $reason: String!, $message: String!) {
  contactUs(clientId: $clientId, email: $email, reason: $reason, message: $message) {
    message
    success
  }
}
    `;
export type ContactUsMutationFn = Apollo.MutationFunction<ContactUsMutation, ContactUsMutationVariables>;

/**
 * __useContactUsMutation__
 *
 * To run a mutation, you first call `useContactUsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUsMutation, { data, loading, error }] = useContactUsMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      email: // value for 'email'
 *      reason: // value for 'reason'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useContactUsMutation(baseOptions?: Apollo.MutationHookOptions<ContactUsMutation, ContactUsMutationVariables>) {
        return Apollo.useMutation<ContactUsMutation, ContactUsMutationVariables>(ContactUsDocument, baseOptions);
      }
export type ContactUsMutationHookResult = ReturnType<typeof useContactUsMutation>;
export type ContactUsMutationResult = Apollo.MutationResult<ContactUsMutation>;
export type ContactUsMutationOptions = Apollo.BaseMutationOptions<ContactUsMutation, ContactUsMutationVariables>;
export const MakeTransactionDocument = gql`
    mutation MakeTransaction($paymentMethodId: ID!, $destination: ID!, $amount: Float!, $purpose: ID!, $key: String!, $notes: String) {
  makeTransaction(paymentMethodId: $paymentMethodId, destination: $destination, amount: $amount, purpose: $purpose, key: $key, notes: $notes) {
    transaction {
      id
      stripeId
      amount
      status
      date
      lastModified
      source {
        username
      }
      destination {
        handle
      }
      notes
    }
    status
  }
}
    `;
export type MakeTransactionMutationFn = Apollo.MutationFunction<MakeTransactionMutation, MakeTransactionMutationVariables>;

/**
 * __useMakeTransactionMutation__
 *
 * To run a mutation, you first call `useMakeTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeTransactionMutation, { data, loading, error }] = useMakeTransactionMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *      destination: // value for 'destination'
 *      amount: // value for 'amount'
 *      purpose: // value for 'purpose'
 *      key: // value for 'key'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useMakeTransactionMutation(baseOptions?: Apollo.MutationHookOptions<MakeTransactionMutation, MakeTransactionMutationVariables>) {
        return Apollo.useMutation<MakeTransactionMutation, MakeTransactionMutationVariables>(MakeTransactionDocument, baseOptions);
      }
export type MakeTransactionMutationHookResult = ReturnType<typeof useMakeTransactionMutation>;
export type MakeTransactionMutationResult = Apollo.MutationResult<MakeTransactionMutation>;
export type MakeTransactionMutationOptions = Apollo.BaseMutationOptions<MakeTransactionMutation, MakeTransactionMutationVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($username: String!, $password: String!, $clientId: String!, $data: UserInput!, $before: String, $after: String, $first: Int, $last: Int) {
  registerUser(username: $username, password: $password, clientId: $clientId, profileData: $data) {
    user {
      ...userFields
    }
    token {
      access
      refresh
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      clientId: // value for 'clientId'
 *      data: // value for 'data'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, baseOptions);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const RemoveFavoriteDocument = gql`
    mutation RemoveFavorite($id: ID!, $before: String, $after: String, $first: Int, $last: Int) {
  removeFavorite(id: $id) {
    success
    user {
      ...userFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type RemoveFavoriteMutationFn = Apollo.MutationFunction<RemoveFavoriteMutation, RemoveFavoriteMutationVariables>;

/**
 * __useRemoveFavoriteMutation__
 *
 * To run a mutation, you first call `useRemoveFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFavoriteMutation, { data, loading, error }] = useRemoveFavoriteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useRemoveFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFavoriteMutation, RemoveFavoriteMutationVariables>) {
        return Apollo.useMutation<RemoveFavoriteMutation, RemoveFavoriteMutationVariables>(RemoveFavoriteDocument, baseOptions);
      }
export type RemoveFavoriteMutationHookResult = ReturnType<typeof useRemoveFavoriteMutation>;
export type RemoveFavoriteMutationResult = Apollo.MutationResult<RemoveFavoriteMutation>;
export type RemoveFavoriteMutationOptions = Apollo.BaseMutationOptions<RemoveFavoriteMutation, RemoveFavoriteMutationVariables>;
export const RemoveUserPaymentMethodDocument = gql`
    mutation RemoveUserPaymentMethod($id: ID!) {
  removeUserPaymentMethod(id: $id) {
    paymentMethod {
      ...pmFields
    }
    success
  }
}
    ${PmFieldsFragmentDoc}`;
export type RemoveUserPaymentMethodMutationFn = Apollo.MutationFunction<RemoveUserPaymentMethodMutation, RemoveUserPaymentMethodMutationVariables>;

/**
 * __useRemoveUserPaymentMethodMutation__
 *
 * To run a mutation, you first call `useRemoveUserPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserPaymentMethodMutation, { data, loading, error }] = useRemoveUserPaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserPaymentMethodMutation, RemoveUserPaymentMethodMutationVariables>) {
        return Apollo.useMutation<RemoveUserPaymentMethodMutation, RemoveUserPaymentMethodMutationVariables>(RemoveUserPaymentMethodDocument, baseOptions);
      }
export type RemoveUserPaymentMethodMutationHookResult = ReturnType<typeof useRemoveUserPaymentMethodMutation>;
export type RemoveUserPaymentMethodMutationResult = Apollo.MutationResult<RemoveUserPaymentMethodMutation>;
export type RemoveUserPaymentMethodMutationOptions = Apollo.BaseMutationOptions<RemoveUserPaymentMethodMutation, RemoveUserPaymentMethodMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String, $password: String!, $clientId: String) {
  resetPassword(token: $token, password: $password, clientId: $clientId) {
    message
    success
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($data: UserInput, $before: String, $after: String, $first: Int, $last: Int) {
  updateUser(userInfo: $data) {
    user {
      ...userFields
    }
    success
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserPaymentMethodDocument = gql`
    mutation UpdateUserPaymentMethod($id: ID!, $data: UpdatePaymentMethodInput!) {
  updateUserPaymentMethod(id: $id, paymentMethod: $data) {
    user {
      id
      username
      profile {
        id
        primaryPaymentMethod {
          ...pmFields
        }
      }
      paymentMethods {
        edges {
          node {
            ...pmFields
          }
        }
      }
    }
    success
    paymentMethod {
      ...pmFields
    }
  }
}
    ${PmFieldsFragmentDoc}`;
export type UpdateUserPaymentMethodMutationFn = Apollo.MutationFunction<UpdateUserPaymentMethodMutation, UpdateUserPaymentMethodMutationVariables>;

/**
 * __useUpdateUserPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateUserPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPaymentMethodMutation, { data, loading, error }] = useUpdateUserPaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPaymentMethodMutation, UpdateUserPaymentMethodMutationVariables>) {
        return Apollo.useMutation<UpdateUserPaymentMethodMutation, UpdateUserPaymentMethodMutationVariables>(UpdateUserPaymentMethodDocument, baseOptions);
      }
export type UpdateUserPaymentMethodMutationHookResult = ReturnType<typeof useUpdateUserPaymentMethodMutation>;
export type UpdateUserPaymentMethodMutationResult = Apollo.MutationResult<UpdateUserPaymentMethodMutation>;
export type UpdateUserPaymentMethodMutationOptions = Apollo.BaseMutationOptions<UpdateUserPaymentMethodMutation, UpdateUserPaymentMethodMutationVariables>;
export const UpdateUserPreferencesDocument = gql`
    mutation UpdateUserPreferences($language: String!, $before: String, $after: String, $first: Int, $last: Int) {
  updateUserPreferences(language: $language) {
    user {
      ...userFields
    }
    success
    message
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateUserPreferencesMutationFn = Apollo.MutationFunction<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>;

/**
 * __useUpdateUserPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPreferencesMutation, { data, loading, error }] = useUpdateUserPreferencesMutation({
 *   variables: {
 *      language: // value for 'language'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useUpdateUserPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>) {
        return Apollo.useMutation<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>(UpdateUserPreferencesDocument, baseOptions);
      }
export type UpdateUserPreferencesMutationHookResult = ReturnType<typeof useUpdateUserPreferencesMutation>;
export type UpdateUserPreferencesMutationResult = Apollo.MutationResult<UpdateUserPreferencesMutation>;
export type UpdateUserPreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>;
export const VerifyUserPaymentMethodDocument = gql`
    mutation VerifyUserPaymentMethod($id: ID!, $first: Float!, $second: Float!) {
  verifyUserPaymentMethod(id: $id, first: $first, second: $second) {
    paymentMethod {
      ...pmFields
    }
    success
  }
}
    ${PmFieldsFragmentDoc}`;
export type VerifyUserPaymentMethodMutationFn = Apollo.MutationFunction<VerifyUserPaymentMethodMutation, VerifyUserPaymentMethodMutationVariables>;

/**
 * __useVerifyUserPaymentMethodMutation__
 *
 * To run a mutation, you first call `useVerifyUserPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserPaymentMethodMutation, { data, loading, error }] = useVerifyUserPaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      second: // value for 'second'
 *   },
 * });
 */
export function useVerifyUserPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<VerifyUserPaymentMethodMutation, VerifyUserPaymentMethodMutationVariables>) {
        return Apollo.useMutation<VerifyUserPaymentMethodMutation, VerifyUserPaymentMethodMutationVariables>(VerifyUserPaymentMethodDocument, baseOptions);
      }
export type VerifyUserPaymentMethodMutationHookResult = ReturnType<typeof useVerifyUserPaymentMethodMutation>;
export type VerifyUserPaymentMethodMutationResult = Apollo.MutationResult<VerifyUserPaymentMethodMutation>;
export type VerifyUserPaymentMethodMutationOptions = Apollo.BaseMutationOptions<VerifyUserPaymentMethodMutation, VerifyUserPaymentMethodMutationVariables>;
export const MyBusinessQueryDocument = gql`
    query MyBusinessQuery($id: ID!) {
  business(id: $id) {
    ...businessFields
  }
}
    ${BusinessFieldsFragmentDoc}`;

/**
 * __useMyBusinessQueryQuery__
 *
 * To run a query within a React component, call `useMyBusinessQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBusinessQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBusinessQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMyBusinessQueryQuery(baseOptions?: Apollo.QueryHookOptions<MyBusinessQueryQuery, MyBusinessQueryQueryVariables>) {
        return Apollo.useQuery<MyBusinessQueryQuery, MyBusinessQueryQueryVariables>(MyBusinessQueryDocument, baseOptions);
      }
export function useMyBusinessQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyBusinessQueryQuery, MyBusinessQueryQueryVariables>) {
          return Apollo.useLazyQuery<MyBusinessQueryQuery, MyBusinessQueryQueryVariables>(MyBusinessQueryDocument, baseOptions);
        }
export type MyBusinessQueryQueryHookResult = ReturnType<typeof useMyBusinessQueryQuery>;
export type MyBusinessQueryLazyQueryHookResult = ReturnType<typeof useMyBusinessQueryLazyQuery>;
export type MyBusinessQueryQueryResult = Apollo.QueryResult<MyBusinessQueryQuery, MyBusinessQueryQueryVariables>;
export const ForgotPasswordDocument = gql`
    query ForgotPassword($email: String!, $clientId: String!) {
  forgotPassword(email: $email, clientId: $clientId) {
    message
    success
  }
}
    `;

/**
 * __useForgotPasswordQuery__
 *
 * To run a query within a React component, call `useForgotPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForgotPasswordQuery({
 *   variables: {
 *      email: // value for 'email'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useForgotPasswordQuery(baseOptions?: Apollo.QueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables>) {
        return Apollo.useQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, baseOptions);
      }
export function useForgotPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables>) {
          return Apollo.useLazyQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, baseOptions);
        }
export type ForgotPasswordQueryHookResult = ReturnType<typeof useForgotPasswordQuery>;
export type ForgotPasswordLazyQueryHookResult = ReturnType<typeof useForgotPasswordLazyQuery>;
export type ForgotPasswordQueryResult = Apollo.QueryResult<ForgotPasswordQuery, ForgotPasswordQueryVariables>;
export const GetMyTransactionsDocument = gql`
    query GetMyTransactions($before: String, $after: String, $first: Int, $last: Int) {
  me {
    id
    profile {
      id
      firstName
      lastName
    }
    transactions(before: $before, after: $after, first: $first, last: $last) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          purpose {
            id
            name
          }
          destination {
            id
            name
            handle
            address {
              id
              street1
              street2
              city
              state
              zipcode
            }
            manager {
              id
              profile {
                id
                firstName
                lastName
              }
            }
          }
          paymentMethod {
            id
            type
            last4
            fullName
            nickName
            brand
            bankName
            expMonth
            expYear
            status
            createdAt
          }
          amount
          status
          date
          lastModified
          notes
          createdAt
        }
      }
    }
  }
}
    `;

/**
 * __useGetMyTransactionsQuery__
 *
 * To run a query within a React component, call `useGetMyTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyTransactionsQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetMyTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyTransactionsQuery, GetMyTransactionsQueryVariables>) {
        return Apollo.useQuery<GetMyTransactionsQuery, GetMyTransactionsQueryVariables>(GetMyTransactionsDocument, baseOptions);
      }
export function useGetMyTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyTransactionsQuery, GetMyTransactionsQueryVariables>) {
          return Apollo.useLazyQuery<GetMyTransactionsQuery, GetMyTransactionsQueryVariables>(GetMyTransactionsDocument, baseOptions);
        }
export type GetMyTransactionsQueryHookResult = ReturnType<typeof useGetMyTransactionsQuery>;
export type GetMyTransactionsLazyQueryHookResult = ReturnType<typeof useGetMyTransactionsLazyQuery>;
export type GetMyTransactionsQueryResult = Apollo.QueryResult<GetMyTransactionsQuery, GetMyTransactionsQueryVariables>;
export const GetPaymentMethodsDocument = gql`
    query GetPaymentMethods {
  me {
    id
    profile {
      id
      primaryPaymentMethod {
        ...pmFields
      }
    }
    paymentMethods {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...pmFields
        }
      }
    }
  }
}
    ${PmFieldsFragmentDoc}`;

/**
 * __useGetPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
        return Apollo.useQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, baseOptions);
      }
export function useGetPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
          return Apollo.useLazyQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, baseOptions);
        }
export type GetPaymentMethodsQueryHookResult = ReturnType<typeof useGetPaymentMethodsQuery>;
export type GetPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodsLazyQuery>;
export type GetPaymentMethodsQueryResult = Apollo.QueryResult<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>;
export const ListTransactionDocument = gql`
    query ListTransaction($before: String, $after: String, $first: Int, $last: Int) {
  listTransaction(first: $first, after: $after) {
    edges {
      node {
        amount
        destination {
          handle
        }
        date
        status
      }
    }
  }
}
    `;

/**
 * __useListTransactionQuery__
 *
 * To run a query within a React component, call `useListTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransactionQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useListTransactionQuery(baseOptions?: Apollo.QueryHookOptions<ListTransactionQuery, ListTransactionQueryVariables>) {
        return Apollo.useQuery<ListTransactionQuery, ListTransactionQueryVariables>(ListTransactionDocument, baseOptions);
      }
export function useListTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTransactionQuery, ListTransactionQueryVariables>) {
          return Apollo.useLazyQuery<ListTransactionQuery, ListTransactionQueryVariables>(ListTransactionDocument, baseOptions);
        }
export type ListTransactionQueryHookResult = ReturnType<typeof useListTransactionQuery>;
export type ListTransactionLazyQueryHookResult = ReturnType<typeof useListTransactionLazyQuery>;
export type ListTransactionQueryResult = Apollo.QueryResult<ListTransactionQuery, ListTransactionQueryVariables>;
export const LoginUserDocument = gql`
    query LoginUser($username: String!, $password: String!, $clientId: String!, $before: String, $after: String, $first: Int, $last: Int) {
  login(username: $username, password: $password, clientId: $clientId) {
    user {
      ...userFields
    }
    token {
      access
    }
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useLoginUserQuery__
 *
 * To run a query within a React component, call `useLoginUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginUserQuery({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      clientId: // value for 'clientId'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useLoginUserQuery(baseOptions?: Apollo.QueryHookOptions<LoginUserQuery, LoginUserQueryVariables>) {
        return Apollo.useQuery<LoginUserQuery, LoginUserQueryVariables>(LoginUserDocument, baseOptions);
      }
export function useLoginUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginUserQuery, LoginUserQueryVariables>) {
          return Apollo.useLazyQuery<LoginUserQuery, LoginUserQueryVariables>(LoginUserDocument, baseOptions);
        }
export type LoginUserQueryHookResult = ReturnType<typeof useLoginUserQuery>;
export type LoginUserLazyQueryHookResult = ReturnType<typeof useLoginUserLazyQuery>;
export type LoginUserQueryResult = Apollo.QueryResult<LoginUserQuery, LoginUserQueryVariables>;
export const MeQueryDocument = gql`
    query MeQuery($before: String, $after: String, $first: Int, $last: Int) {
  me {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useMeQueryQuery__
 *
 * To run a query within a React component, call `useMeQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQueryQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useMeQueryQuery(baseOptions?: Apollo.QueryHookOptions<MeQueryQuery, MeQueryQueryVariables>) {
        return Apollo.useQuery<MeQueryQuery, MeQueryQueryVariables>(MeQueryDocument, baseOptions);
      }
export function useMeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQueryQuery, MeQueryQueryVariables>) {
          return Apollo.useLazyQuery<MeQueryQuery, MeQueryQueryVariables>(MeQueryDocument, baseOptions);
        }
export type MeQueryQueryHookResult = ReturnType<typeof useMeQueryQuery>;
export type MeQueryLazyQueryHookResult = ReturnType<typeof useMeQueryLazyQuery>;
export type MeQueryQueryResult = Apollo.QueryResult<MeQueryQuery, MeQueryQueryVariables>;
export const MyFavoritesDocument = gql`
    query MyFavorites($before: String, $after: String, $first: Int, $last: Int) {
  me {
    favorites(before: $before, after: $after, first: $first, last: $last) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          name
          handle
          address {
            id
            street1
            street2
            city
            state
            zipcode
          }
          manager {
            id
            username
            profile {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMyFavoritesQuery__
 *
 * To run a query within a React component, call `useMyFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyFavoritesQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useMyFavoritesQuery(baseOptions?: Apollo.QueryHookOptions<MyFavoritesQuery, MyFavoritesQueryVariables>) {
        return Apollo.useQuery<MyFavoritesQuery, MyFavoritesQueryVariables>(MyFavoritesDocument, baseOptions);
      }
export function useMyFavoritesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyFavoritesQuery, MyFavoritesQueryVariables>) {
          return Apollo.useLazyQuery<MyFavoritesQuery, MyFavoritesQueryVariables>(MyFavoritesDocument, baseOptions);
        }
export type MyFavoritesQueryHookResult = ReturnType<typeof useMyFavoritesQuery>;
export type MyFavoritesLazyQueryHookResult = ReturnType<typeof useMyFavoritesLazyQuery>;
export type MyFavoritesQueryResult = Apollo.QueryResult<MyFavoritesQuery, MyFavoritesQueryVariables>;