// src/gatsby-theme-apollo/client.js
import fetch from "isomorphic-fetch";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";

import { getToken, isLoggedIn, logout } from "../components/app/services/auth";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    // logout();
  }
});

const httpLink = createHttpLink({
  uri: process.env.GIVFAST_API_URL,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const storedToken = localStorage.getItem("@GivFastToken");

  const token = isLoggedIn() ? getToken().access : "";

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// const formatter = new Intl.NumberFormat("en-US", {
//   style: "currency",
//   currency: "USD",
// });

export const cache = new InMemoryCache({
  typePolicies: {
    User: {
      // keyFields: ["username"],
      fields: {
        transactions: relayStylePagination(),
        paymentMethods: relayStylePagination(),
        favorites: relayStylePagination(),
      },
    },
    // Transaction: {
    //   fields: {
    //     amount: {
    //       read(amount) {
    //         return formatter.format(amount);
    //       },
    //     },
    //   },
    // },
  },
});

export const client = new ApolloClient({
  cache,
  link: from([errorLink, authLink, httpLink]),
});

export default client;
