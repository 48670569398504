import client, { cache } from "../../../gatsby-theme-apollo/client";
import { MeQueryDocument } from "../generated/graphql";

const tokenName = "@GivFastToken";

export const isBrowser = (): boolean => typeof window !== "undefined";

export const getToken = (): any =>
  isBrowser() && window.localStorage.getItem(tokenName)
    ? JSON.parse(window.localStorage.getItem(tokenName))
    : {};

const setToken = token => {
  if (isBrowser()) {
    window.localStorage.setItem(tokenName, JSON.stringify(token));
  }
};

export const handleLogin = ({ user, token }) => {
  if (user) {
    // update cache with user details.
    const query = MeQueryDocument;
    // const data = client.readQuery({ query });
    // console.log(`data: `, data);
    client.writeQuery({
      query,
      data: {
        me: { ...user },
      },
    });

    return setToken(token);
  }

  return false;
};

export const isLoggedIn = (): boolean => {
  const token = getToken();

  if (token && token.access) {
    return true;
  }

  return false;
};

export const logout = (callback): void => {
  setToken(null);
  client.clearStore();
  cache.reset();
  if (callback) callback();
};
